.slick-slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  animation: fadeIn 2s ease-in forwards;

  * {
    height: 100%;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.image {
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
