body {
  background-color: black;
  padding: 0;
  margin: 0;
}

.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.logotype {
  position: relative;
  height: 70vh;
  width: auto;
  mix-blend-mode: difference;
}

.circle {
  animation: rotate 100s linear infinite;
  transform-origin: center;
}
.berndt {
  animation: rotate 140s linear infinite;
  transform-origin: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}
